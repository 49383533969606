import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { BackOfficeComponent } from "../back-office/back-office.component";
import { AuthGuard } from "../auth.guard";

const routes: Routes = [
  {
    path: "back-office",
    component: BackOfficeComponent,
    children: [
      {
        path: "globalqueueold/:emailer/:flow",
        loadChildren: () => import('./globalqueueold/globalqueueold.module').then(m => m.GlobalqueueoldModule),

        // canActivateChild: [AuthGuard],
      }, {
        path: "home",
        // loadChildren: "./home/home.module#HomeModule" 
        loadChildren: () => import('./home/home.module').then(m => m.HomeModule)

      },

      {
        path: "resetpwd",
        // loadChildren: () => import('./changepwd/changepwd.module').then (m => m.ChangepwdModule)

        loadChildren: () => import('./changepwd/changepwd.module').then(m => m.ChangepwdModule),
      },
      {
        path: "returnreason",
        // loadChildren: "./ReturnReason/returnreason.module#ReturnReasonModule",
        loadChildren: () => import('./ReturnReason/returnreason.module').then(m => m.ReturnReasonModule),

        canActivateChild: [AuthGuard],
      },

      {
        path: "othersetup",
        // loadChildren: "./OtherSetup/OtherSetup.module#OtherSetupModule",
        loadChildren: () => import('./OtherSetup/OtherSetup.module').then(m => m.OtherSetupModule),

        canActivateChild: [AuthGuard],
      },
      {
        path: "notificationtemplate",
        // loadChildren:
        //   "./notificationtemplate/notificationtemplate.module#NotificationTemplateModule",
        loadChildren: () => import('./notificationtemplate/notificationtemplate.module').then(m => m.NotificationTemplateModule),

        canActivateChild: [AuthGuard],
      },
      {
        path: "modelmaster",
        // loadChildren: "./ModelMaster/modelmaster.module",
        loadChildren: () => import('./ModelMaster/modelmaster.module').then(m => m.ModelMasterModule),

        canActivateChild: [AuthGuard],
      },
      {
        path: "role",
        // loadChildren: "./#",
        loadChildren: () => import('./Role/role.module').then(m => m.RoleModule),

        canActivateChild: [AuthGuard],
      },

      {
        path: "fuser/PTR",
        // loadChildren: "./user/user.module#",
        loadChildren: () => import('./user/user.module').then(m => m.UserModule),

        canActivateChild: [AuthGuard],
      },
      {
        path: "inventoryreport",
        // loadChildren: "./",
        loadChildren: () => import('./Reports/report.module').then(m => m.ReportModule),

        canActivateChild: [AuthGuard],
      },
      {
        path: "region",
        // loadChildren: "./Region/region.module#",
        loadChildren: () => import('./Region/region.module').then(m => m.RegionModule),

        canActivateChild: [AuthGuard],
      },

      {
        path: "rejectreason",
        // loadChildren: "./reject-reason/rejectreason.module#RejectReasonModule",
        loadChildren: () => import('./reject-reason/rejectreason.module').then(m => m.RejectReasonModule),

        canActivateChild: [AuthGuard],
      },
      {
        path: "language",
        // loadChildren: "./#",
        loadChildren: () => import('./language/language.module').then(m => m.LanguageModule),

        canActivateChild: [AuthGuard],
      },
      {
        path: "returns",
        // loadChildren: "./#",
        loadChildren: () => import('./returns/returns.module').then(m => m.ReturnsModule),

        canActivateChild: [AuthGuard],
      },
      {
        path: "ga_returns",
        // loadChildren: "./#",
        loadChildren: () => import('./ga-returns/ga-returns.module').then(m => m.GaReturnsModule),

        canActivateChild: [AuthGuard],
      },
      {
        path: "con_returns",
        // loadChildren:
        //   "./#",
        loadChildren: () => import('./consumer-returns/consumer-returns.module').then(m => m.ConsumerReturnsModule),

        canActivateChild: [AuthGuard],
      },
      {
        path: "jwplus",
        // loadChildren:
        // "./jwplus-returns/jwplus-returns.module#",
        loadChildren: () => import('./jwplus-returns/jwplus-returns.module').then(m => m.JwplusReturnsModule),

        canActivateChild: [AuthGuard],
      },
      {
        path: "reports",
        // loadChildren: "./#",
        loadChildren: () => import('./analytics/analytics.module').then(m => m.AnalyticsModule),
        canActivateChild: [AuthGuard],
      },

      {
        path: "brandsuser/PTR",
        // loadChildren: "./#",
        loadChildren: () => import('./brands/user.module').then(m => m.UserModule),

        canActivateChild: [AuthGuard],
      },
      {
        path: "pendinguser",
        // loadChildren: "./#",
        loadChildren: () => import('./pending-user-approval/pending-user-approval.module').then(m => m.PendingUserApprovalModule),

        canActivateChild: [AuthGuard],
      },
      {
        path: "userSearchMgmt",
        // loadChildren: "./#",
        loadChildren: () => import('./user-search-mgmt/user-search-mgmt.module').then(m => m.UserSearchMgmtModule),
        canActivateChild: [AuthGuard],
      },
      {
        path: "error",
        // loadChildren: "./#" },
        loadChildren: () => import('./error/error.module').then(m => m.ErrorModule),
      },

      {
        path: "consumereports",
        // loadChildren: "./#",
        loadChildren: () => import('./consumer-report/consumer-report.module').then(m => m.ConsumerReportModule),

        canActivateChild: [AuthGuard],
      },
      {
        path: "gaiareports",
        // loadChildren: "./#",
        loadChildren: () => import('./gaia-reports/gaia-report.module').then(m => m.gaiaReportModule),

        canActivateChild: [AuthGuard],
      },

      {
        path: "manualrma",
        // loadChildren: "./#",
        loadChildren: () => import('./manual-rma/manual-rma.module').then(m => m.ManualRmaModule),

        canActivateChild: [AuthGuard],
      },

      {
        path: "jcp",
        // loadChildren: "./#",
        loadChildren: () => import('./Jcp-returns/Jcp-returns.module').then(m => m.JcpReturnsModule),

        canActivateChild: [AuthGuard],
      },
      {
        path: "globalqueue",
        // loadChildren: "./#",
        loadChildren: () => import('./globalqueue/globalqueue.module').then(m => m.GlobalqueueModule),

        canActivateChild: [AuthGuard],
      },
      {
        path: "globalqueueold",
        // loadChildren: "./#",
        loadChildren: () => import('./globalqueueold/globalqueueold.module').then(m => m.GlobalqueueoldModule),

        // canActivateChild: [AuthGuard],
      },

      {
        path: "globalreports",
        // loadChildren: "./#",
        loadChildren: () => import('./globalreport/globalreport.module').then(m => m.GlobalreportModule),

        canActivateChild: [AuthGuard],
      },
      {
        path: "warrantycheck",
        loadChildren: () => import('./warrantychecker/warrantychecker.module').then(m => m.WarrantycheckerModule),

        // canActivateChild: [AuthGuard],
      },
      {
        path: "specfunc",
        // loadChildren: "./#",
        loadChildren: () => import('./special-func/spec_fun.module').then(m => m.SpecialReturnsModule),

        canActivateChild: [AuthGuard],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class BackOfficeRoutingModule { }
