export const environment = {
  production: true,
  ohflag: false,
  scheme: "https",
  name: "DEV",
  alias: "jabra-api",
  host: "reverselogix.io",
  webapi:"https://jabra-coreapi-prod1-weu.azurewebsites.net/",
  googleCaptcha: "6LcQ3bwaAAAAAGbsfndSgmxKIJazautSwwUmt0Eg",
  envsource:"prod",
  encryptionKey: "$aS^AmR$@Cx2o19!",
  encryptionKeyBULK: "$aS^AmR",
  azureADsso:
    "https://servicenet.jabra.com/portal/res/return/t3/dsc2/prod/search/en-us",
  loginRedirect:
    "https://servicenet.jabra.com/portal/res/return/t3/dsc2/prod/login/en-us",
  b2cSignInUpAuthority:
    "https://account.gn.com/6c61e241-6a49-44e6-a673-d6b893621396/B2C_1A_JABRAJSNHLE_SignUpOrSignin/",
  b2cResetPswdAuthority:
    "https://6c61e241-6a49-44e6-a673-d6b893621396/B2C_1A_JABRAJSNHLE_PasswordReset/B2C_1A_JABRAJSNHLE_PasswordReset/",
  policysignUpSignIn: "B2C_1A_JABRAJSNHLE_SignUpOrSignin",
  policyresetPassword: "B2C_1A_JABRAJSNHLE_PasswordReset",
  b2cClientID: "9b6f98a0-9bd1-4e5b-b2ba-225fda5dd49b",
  b2cGetaccesstoServicenet: "https://servicenet-onboard.jabra.com/?ui_locales=",
  b2cSignOutAuthority: "https://account.gn.com/6c61e241-6a49-44e6-a673-d6b893621396/B2C_1A_JABRAJSNHLE_SignUpOrSignin/oauth2/v2.0/logout?post_logout_redirect_uri=https://servicenet.jabra.com/portal/res/return/t3/dsc2/prod/login/",
  POBoxmatching: ['P O BOX' , 'PO BOX'  , 'POBOX', 'P. O. BOX','P.O. BOX','P.O.BOX' ,'PO','Parcel locker' ,'Parcel' ,'Locker',
  'P. O BOX','P O. BOX','PO. BOX','P.O BOX','PO.BOX','P OBOX' ],
  us_pr_notsetup: "https://account.gn.com/6c61e241-6a49-44e6-a673-d6b893621396/b2c_1a_jabrajsnhle_signuporsignin/oauth2/v2.0/authorize?prompt=login&response_type=id_token&scope=openid%20profile&client_id=9b6f98a0-9bd1-4e5b-b2ba-225fda5dd49b&redirect_uri=https%3A%2f%2fservicenet-onboard.jabra.com%2fonboarding&state=eyJpZCI6ImQwODY4OWUzLThjYTYtNDgzYy05ZTViLTFhODFmNGUxNTJmZSIsInRzIjoxNjM1OTQ4NTI5LCJtZXRob2QiOiJyZWRpcmVjdEludGVyYWN0aW9uIn0%3D&nonce=480cb2fd-7777-4987-9505-a2c41ecfd748&client_info=1&x-client-SKU=MSAL.JS&x-client-Ver=1.4.0&client-request-id=c99bd4f3-a468-4a1d-8709-acb9beed7e1c&response_mode=fragment"
// ,Email_Validate_Applicable:true,
// Image_Functionality_Applicable:true

};
